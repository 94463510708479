import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GlobalState } from './global.state';
import { BaThemePreloader, BaThemeSpinner } from './shared/services';
import { BaseComponent } from 'app/shared/infrastructure';
import { LoaderService } from 'app/loader/_services/loader.service';
/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'app',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class App extends BaseComponent implements AfterContentChecked {
    isMenuCollapsed: boolean = false;
    constructor(
        private _state: GlobalState,
        private ref: ChangeDetectorRef,
        public _loaderService: LoaderService,
        private _spinner: BaThemeSpinner) {
        super();
        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
    }
    ngAfterContentChecked() {
        this.ref.detectChanges();
    }

    public ngAfterViewInit(): void {
        // hide spinner once all loaders are completed
        BaThemePreloader.load().then(() => {
            this._spinner.hide();
        });
    }
}