export class ReceiveOrderSearch {

    patientId?: number;
    itemId?: number;
    sectionId?: number;
    fromOrderDate?: Date;
    toOrderDate?: Date;
    orderStatusId? : number;
    orderId?: number;
    patientInformation?: string;
    doctorId?: number;
    pageIndex: number = 1;
    rowNo: number = 10;
    encounterId?: number;
    patientTypeId?: number;

    constructor() {

    }
}
