import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map } from 'rxjs';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { BillService } from '../../../models/orders-and-billing/bill-service';
import { BillServicesSearchFilter } from 'app/shared/models/orders-and-billing/billServicesSearchFilter';
import { StatementTransactionDiscountDto } from 'app/shared/models/orders-and-billing/statement-transaction-discount-dto';
import { AdmissionServiceApiConfiguration } from '../../admissionService/admissionServiceApiConfiguration';
import { ApprovalInvoiceDetails, ValidateStatment } from 'app/shared/models/orders-and-billing';

@Injectable()
export class BillServicesService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _getByFillter: string;
    private _getNotCanceledByFillter: string;
    private _getDiscountableElement: string;
    private _getDiscountableItemDetail: string;
    private _discount: string;
    private _invoiceIndex: string;
    private _invoiceIndexDetail: string;
    private _orderActionInvoiceDetail: string;
    private _netAmountByStatmentId: string;
    private _getLastActionDateByStatementId: string;
    private _getDischargeDate: string;
    private _getReleaseDate: string;
    private _checkIfPaid: string;
    private _approvalIndexDetail: string;
    private _cashStatementInvoiceNetUrl;
    private _getElementsByStatementId: string;
    private _approveDiscount: string;
    private _pendingDiscount: string;
    private _originalIssueDate: string;
    private _checkStatementIsIssued: string;
    private _checkActionDate: string;


    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration, private _admissionServiceApiConfiguration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.billServices.url;
        this._getByFillter = this.actionUrl + _configuration.billServices.getByFillter;
        this._getNotCanceledByFillter = this.actionUrl + _configuration.billServices.getNotCanceledByFillter;
        this._getDiscountableElement = this.actionUrl + _configuration.billServices.getDiscountableElement;
        this._discount = this.actionUrl + _configuration.billServices.discount;
        this._invoiceIndex = this.actionUrl + _configuration.billServices.invoiceIndex;
        this._invoiceIndexDetail = this.actionUrl + _configuration.billServices.invoiceIndexDetail;
        this._getDiscountableItemDetail = this.actionUrl + _configuration.billServices.getDiscountableItemDetail;
        this._orderActionInvoiceDetail = this.actionUrl + _configuration.billServices.orderActionInvoiceDetail;
        this._netAmountByStatmentId = this.actionUrl + _configuration.billServices.netAmountByStatmentId;
        this._getLastActionDateByStatementId = this.actionUrl + _configuration.billServices.getLastActionDateByStatementId;
        this._getDischargeDate = this.actionUrl + _configuration.patientStatement.getDischargeDate;
        this._getReleaseDate = `${this._admissionServiceApiConfiguration.server}${this._admissionServiceApiConfiguration.admission.getReleaseDate}`;
        this._checkIfPaid = this.actionUrl + _configuration.billServices.checkIfPaid;
        this._approvalIndexDetail = this.actionUrl + _configuration.billServices.approvalIndexDetails;
        this._cashStatementInvoiceNetUrl = this.actionUrl + _configuration.billServices.cashStatementInvoiceNetUrl;
        this._getElementsByStatementId = this.actionUrl + _configuration.billServices.getElementsByStatementId;
        this._approveDiscount = this.actionUrl + _configuration.billServices.approveDiscount;
        this._pendingDiscount = this.actionUrl + _configuration.billServices.pendingDiscount;
        this._originalIssueDate = this.actionUrl + _configuration.patientBill.originalIssueDate;
        this._checkStatementIsIssued = this.actionUrl + _configuration.patientBill.checkStatementIsIssued;
        this._checkActionDate = this.actionUrl + _configuration.billServices.checkActionDate;
    }

    getNotCanceledByFillter = (patientId, encounterId, patientStatmentId, filter: BillServicesSearchFilter = new BillServicesSearchFilter(), pageNo = 1, rowNo = 10): Observable<any> => {
        let queryString: string = '';
        queryString += '?patientId=';
        queryString += patientId;

        queryString += '&encounterId=';
        queryString += encounterId;

        queryString += '&patientStatmentId=';
        queryString += patientStatmentId;

        queryString += '&pageNo=';
        queryString += pageNo;

        queryString += '&rowNo=';
        queryString += rowNo;

        queryString += '&itemCode=';
        if (filter.itemCode)
            queryString += filter.itemCode;

        queryString += '&serviceName=';
        if (filter.serviceName)
            queryString += filter.serviceName;

        queryString += '&quantity=';
        if (filter.quantity)
            queryString += filter.quantity;

        queryString += '&unit=';
        if (filter.unit)
            queryString += filter.unit;

        queryString += '&price=';
        if (filter.price)
            queryString += filter.price;

        queryString += '&netDiscount=';
        if (filter.netDiscount)
            queryString += filter.netDiscount;

        queryString += '&netAmount=';
        if (filter.netAmount)
            queryString += filter.netAmount;

        queryString += '&actionDate=';
        if (filter.actionDate)
            queryString += filter.actionDate.toLocaleDateString();

        return this._http.get<any>(this._getNotCanceledByFillter + queryString)
            .pipe(
                map(response => {
                    let objects = response;
                    let items: BillService[] = [];
                    for (let index in objects.Results) {
                        items.push(new BillService(objects.Results[index]));
                    }
                    return { Results: items, TotalRecords: objects.TotalRecords } || {};
                })
            );
    }
    getByFillter = (patientId, encounterId, patientStatmentId, pageNo, rowNo, filter: BillServicesSearchFilter): Observable<any> => {
        let queryString: string = '';
        queryString += '?patientId=';
        queryString += patientId;

        queryString += '&encounterId=';
        queryString += encounterId;

        queryString += '&patientStatmentId=';
        queryString += patientStatmentId;

        queryString += '&pageNo=';
        queryString += pageNo;

        queryString += '&rowNo=';
        queryString += rowNo;

        queryString += '&itemCode=';
        if (filter.itemCode)
            queryString += filter.itemCode;

        queryString += '&serviceName=';
        if (filter.serviceName)
            queryString += filter.serviceName;

        queryString += '&quantity=';
        if (filter.quantity)
            queryString += filter.quantity;

        queryString += '&unit=';
        if (filter.unit)
            queryString += filter.unit;

        queryString += '&price=';
        if (filter.price)
            queryString += filter.price;

        queryString += '&netDiscount=';
        if (filter.netDiscount)
            queryString += filter.netDiscount;

        queryString += '&netAmount=';
        if (filter.netAmount)
            queryString += filter.netAmount;

        queryString += '&actionDate=';
        if (filter.actionDate)
            queryString += filter.actionDate.toLocaleDateString();

        return this._http.get<any>(this._getByFillter + queryString)
            .pipe(
                map(response => {
                    let objects = response;
                    let items: BillService[] = [];
                    for (let index in objects.Results) {
                        items.push(new BillService(objects.Results[index]));
                    }
                    return { Results: items, TotalRecords: objects.TotalRecords } || {};
                })
            );
    }

    getDiscountableElement = (patientId, encounterId, patientStatmentId): Observable<BillService[]> => {
        return this._http.get<any>(this._getDiscountableElement + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientStatmentId=' + patientStatmentId);
    }

    getDiscountableItemDetail = (patientId, encounterId, patientStatmentId, elements): Observable<BillService[]> => {
        return this._http.get<any>(this._getDiscountableItemDetail + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientStatmentId=' + patientStatmentId +
            '&&elements=' + elements)
            .pipe(
                map((response: Response) => {
                    let objects = response;
                    let items: BillService[] = [];
                    for (let index in objects) {
                        items.push(new BillService(objects[index]));
                    }
                    return items || [];
                })
            );
    }


    discount = (statementTransactionDiscountDto: StatementTransactionDiscountDto): Observable<any> => {
        return this._http.post<any>(this._discount, JSON.stringify(statementTransactionDiscountDto));
    }

    getInvoiceIndex = (statementId: number, fromActionDate?: Date, toActionDate?: Date): Observable<BillService[]> => {
        const fromActionDateSrting = fromActionDate ? fromActionDate.toLocaleDateString() : null;
        const toActionDateString = toActionDate ? toActionDate.toLocaleDateString() : null;

        const url = `${this._invoiceIndex}?statementId=${statementId}&&fromActionDate=${fromActionDateSrting}&&toActionDate=${toActionDateString}`;
        return this._http.get<any>(url);
    }

    getInvoiceIndexDetail = (statementId: number, elementId: number, fromActionDate?: Date, toActionDate?: Date): Observable<BillService[]> => {
        const fromActionDateSrting = fromActionDate ? fromActionDate.toLocaleDateString() : null;
        const toActionDateString = toActionDate ? toActionDate.toLocaleDateString() : null;

        const url = `${this._invoiceIndexDetail}?statementId=${statementId}&&elementId=${elementId}&&fromActionDate=${fromActionDateSrting}&&toActionDate=${toActionDateString}`;
        return this._http.get<any>(url);
    }

    getByEncounterId = (encounterId): Observable<any> => {
        return this._http.get<any>(this.url + `/BillServicesEncounter?encounterId=${encounterId}`);
    }

    getItemByEncounterId = (patientId?: any, encounterId?: any): Observable<any> => {
        return this._http.get<any>(this.url + `/GetItemByEncounter?patinetId=${patientId}&&encounterId=${encounterId}`);
    }

    getItemByApprovalId = (approvalId?: number): Observable<any> => {
        return this._http.get<any>(this.url + `/GetItemByApprovalId?approvalId=${approvalId}`);
    }

    cancelServiceById = (serviceId): Observable<any> => {
        return this._http.put<any>(this.url + `/CancelBillService?serviceId=${serviceId}`, null);
    }

    getOrderActionInvoiceDetail = (orderActionId: number, actionSourceId: number): Observable<BillService[]> => {

        const url = `${this._orderActionInvoiceDetail}?orderActionId=${orderActionId}&&actionSourceId=${actionSourceId}`;

        return this._http.get<any>(url);
    }

    getNetAmountByStatmentId = (patientStatmentId): Observable<any> => {
        return this._http.get<any>(this._netAmountByStatmentId + `?patientStatmentId=${patientStatmentId}`);
    }

    getLastActionDateByStatementId = (statmentId: number): Observable<any> => {
        return this._http.get<any>(`${this._getLastActionDateByStatementId}/${statmentId}`);
    }

    getLastActionDateAndDischargeDate = (statementId: number, patientId: number, encounterId: number, patientTypeId: number): Observable<any> => {
        const dischargeDateUrl = `${this._getDischargeDate}?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}`;
        const releaseDateUrl = `${this._getReleaseDate}?patientId=${patientId}&encounterId=${encounterId}`;

        let lastActionRequest = this._http.get<any>(`${this._getLastActionDateByStatementId}/${statementId}`).pipe(map(res => res))
        let dischargeDat = this._http.get<any>(dischargeDateUrl).pipe(map(res => res));
        let releaseDate = this._http.get<any>(releaseDateUrl).pipe(map(res => res))
        return forkJoin([lastActionRequest, releaseDate, dischargeDat]);
    }

    checkIfPaid = (statmentId: number): Observable<any> => {
        return this._http.get<any>(`${this._checkIfPaid}/${statmentId}`);
    }

    getApprovalIndexDetail = (statementId: number, elementId: number, actionId: number): Observable<ApprovalInvoiceDetails[]> => {
        const url = `${this._approvalIndexDetail}?statementId=${statementId}&&elementId=${elementId}&&actionId=${actionId}`;
        return this._http.get<any>(url);
    }

    getCashStatementInvoiceNet = (patientId: number, encounterId: number, orderId: number): Observable<any> => {
        return this._http.get<any>(`${this._cashStatementInvoiceNetUrl}?patientId=${patientId}&encounterId=${encounterId}&orderId=${orderId}`);
    }

    getElementsByStatementId = (statementId: number): Observable<any> => {
        return this._http.get<any>(`${this._getElementsByStatementId}?statementId=${statementId}`);
    }

    approveDiscountTransaction = (patientStatementId, transactionId): Observable<any> => {
        return this._http.put<any>(`${this._approveDiscount}?patientStatementId=${patientStatementId}&transactionId=${transactionId}`, patientStatementId)
    }

    pendingDiscount = (statementTransactionDiscountDto: StatementTransactionDiscountDto): Observable<any> => {
        return this._http.post<any>(this._pendingDiscount, JSON.stringify(statementTransactionDiscountDto));
    }

    originalIssueDate(patientStatementId: number): Observable<any> {
        return this._http.get<any>(`${this._originalIssueDate}?patientStatementId=${patientStatementId}`)
    }

    checkStatementIsIssued(patientStatementId: number): Observable<any> {
        return this._http.get<any>(`${this._checkStatementIsIssued}?patientStatementId=${patientStatementId}`)
    }

    checkActionDate(validateStatment: ValidateStatment): Observable<any> {
        return this._http.post<any>(this._checkActionDate, JSON.stringify(validateStatment))
    }
}
