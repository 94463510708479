import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { FacilityUnit } from 'app/shared/models/core/facility-unit';
import { FacilityUnitNurse } from 'app/shared/models/core/facility-unit-nurse';
import { FacilityUnitSection } from 'app/shared/models/core/facility-unit-section';

@Injectable()
export class FacilityUnitService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getFacilityUnitNurses: string;
    private _getFacilityUnitSections: string;
    private _insertFacilityUnitSection: string;
    private _updateFacilityUnitSection: string;
    private _insertFacilityUnitNurse: string;
    private _updateFacilityUnitNurse: string;
    private _getNurseSections: string;
    private _getNurseBySectionId: string;
    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.facilityUnit.url;
        this._getFacilityUnitNurses = this.actionUrl + _configuration.facilityUnit.getFacilityUnitNurses;
        this._getFacilityUnitSections = this.actionUrl + _configuration.facilityUnit.getFacilityUnitSections;

        this._insertFacilityUnitSection = this.actionUrl + _configuration.facilityUnit.insertFacilityUnitSection;
        this._updateFacilityUnitSection = this.actionUrl + _configuration.facilityUnit.updateFacilityUnitSection;
        this._insertFacilityUnitNurse = this.actionUrl + _configuration.facilityUnit.insertFacilityUnitNurse;
        this._updateFacilityUnitNurse = this.actionUrl + _configuration.facilityUnit.updateFacilityUnitNurse;
        this._getNurseSections = this.actionUrl + _configuration.facilityUnit.getAllNursesSections;
        this._getNurseBySectionId = this.actionUrl + _configuration.facilityUnit.getNurseBySectionId;
    }

    getAll = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    insert = (data: FacilityUnit): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: FacilityUnit): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    getFacilityUnitNurses = (id): Observable<any> => {
        return this._http.get<any>(this._getFacilityUnitNurses + "?id=" + id);
    }

    getFacilityUnitSections = (id): Observable<any> => {
        return this._http.get<any>(this._getFacilityUnitSections + "?id=" + id);
    }

    insertFacilityUnitSection(data: FacilityUnitSection) {
        return this._http.post<any>(this._insertFacilityUnitSection, JSON.stringify(data));
    }

    updateFacilityUnitSection(data: FacilityUnitSection) {
        return this._http.put<any>(this._updateFacilityUnitSection, JSON.stringify(data));
    }

    insertFacilityUnitNurse(data: FacilityUnitNurse) {
        return this._http.post<any>(this._insertFacilityUnitNurse, JSON.stringify(data));
    }

    updateFacilityUnitNurse(data: FacilityUnitNurse) {
        return this._http.put<any>(this._updateFacilityUnitNurse, JSON.stringify(data));
    }
    getAllNursesSections = (): Observable<any> => {
        return this._http.get<any>(this._getNurseSections);
    }

    getNursesBySectionId = (sectionId:number): Observable<any> => {
        return this._http.get<any>(this._getNurseBySectionId + "?sectionId=" + sectionId);
    }
}
