export enum VisitRequiredField {
    FirstName = 1,
    FatherName = 2,
    MiddleName = 3,
    LastName = 4,
    OtherFirstName = 5,
    OtherFatherName = 6,
    OtherMiddleName = 7,
    OtherLastName = 8,
    BirthDate = 9,
    GenderId = 10,
    NationalityId = 11,
    NationalCode = 12,
    TelCode = 13,
    DocumentCode = 14,
    Address = 15,
    DocumentType = 16,
    MotherName=17,
    CountryId = 18,
    CityId = 19,
    MatirialStatus = 20,
    Area = 21,
    PrimaryDoctor = 22,
    PreferredLanguage =23,
    PreferredMethodOfCommunication=24,
    HowDidYouHearAboutUs=25,
    LastHospitalVisited =26,
}
