export class ApprovalService {
    Id: number = null;
    ApprovalId: number;
    Quantity: number;
    UnitId: number;
    ServiceLimit: number;
    ServicePercentage: number;
    ServiceCashlimit: number;
    Title: string;
    ServiceCashLimit?: any;
    ForeignTitle: string;
    Status: number;
    ApprovalNo: number;
    ToothNumber: number;
    ToothSurface: number;
    ActionId: number;
    ActionSourceId: number;
    DrugIntervalInfo: string;
    DentalDoctorId?: number;
    Code:string;
    Price:number;
    DiscountAmount:number;
    NetPrice:number;
    NphiesCode:number;
    VatAmount:number;
}