import {
    QueryModelApiConfiguration,
    PatientBasicSearchService,
    SearchPatientHasAdmissionService,
    PatientReservationSearchService,
    PatientDischargeSearchService,
    PatientTransferSearchService,
    PatientStatmentSearchService,
    AdmissionStationSearchService,
    PatientOrderSearchService,
    TodayCashBoxSearchService,
    MainFundSearchService,
    PatientOpenAndHaltStatmentSearchService,
    CalculateAccomodationService,
    PatientCalculateVisitTransferService,
    SapControlSearchService,
    SearchCashBoxTransferService,
    PharmacyOrderSearchService,
    DiclosureInvoiveService,
    PhysicianDesktopSearchService,
    HospitalPerformanceService,
    HospitalIncomeService,
    PatientVisitSearchService,
    DashboardDoctorPerformanceService,
    InvoiceService,
    PharmacyAntibioticSearchService,
    PharmacyMedicationHistorySearchService,
    ErpFailedTransactionService,
    DoctorFeesStationService,
    OrderActionAuditSearchService,
    TrackingSettlementService,
    ConsultationTypesDetailSearchService,
    RevenueService,
    PendingAreaService,
    LabPathologyService,
    RadiologyService,
    SpecimenTransactionSearchService,
    ItemService,
    ConsultationRequestSearchService,
    DoctorCommissionService,
    ReceiveEmergencyOrdersService,
    ApprovalService,
    DocumentAlertSetupSearchService,
    PendingAreaManagementService,
    PatientDonorService,
    PatientOpenedHaltedEncountersService,
    PrintersetupService,
    ClaimPatientAttachmentService,
    EligibilityOrderResultService
} from './services/queryModel';
import { PayerBalanceService } from './services/queryModel/payer-balance';

export const NGA_QUERYMODEL_SERVICES = [
    QueryModelApiConfiguration,
    PatientBasicSearchService,
    SearchPatientHasAdmissionService,
    PatientReservationSearchService,
    PatientDischargeSearchService,
    PatientTransferSearchService,
    PatientStatmentSearchService,
    AdmissionStationSearchService,
    PatientOrderSearchService,
    TodayCashBoxSearchService,
    MainFundSearchService,
    PatientOpenAndHaltStatmentSearchService,
    CalculateAccomodationService,
    PatientCalculateVisitTransferService,
    SapControlSearchService,
    SearchCashBoxTransferService,
    PharmacyOrderSearchService,
    DiclosureInvoiveService,
    PhysicianDesktopSearchService,
    HospitalPerformanceService,
    HospitalIncomeService,
    PatientVisitSearchService,
    DashboardDoctorPerformanceService,
    InvoiceService,
    PharmacyAntibioticSearchService,
    PharmacyMedicationHistorySearchService,
    ErpFailedTransactionService,
    DoctorFeesStationService,
    OrderActionAuditSearchService,
    TrackingSettlementService,
    ConsultationTypesDetailSearchService,
    RevenueService,
    PendingAreaService,
    LabPathologyService,
    RadiologyService,
    SpecimenTransactionSearchService,
    ItemService,
    ConsultationRequestSearchService,
    DoctorCommissionService,
    ReceiveEmergencyOrdersService,
    ApprovalService,
    DocumentAlertSetupSearchService,
    PendingAreaManagementService,
    PatientDonorService,
    PatientOpenedHaltedEncountersService,
    PrintersetupService,
    PayerBalanceService,
    ClaimPatientAttachmentService,
    EligibilityOrderResultService
];
