import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concatMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { PharmacyApiConfiguration } from '../../pharmacy/apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { VisitAdmissionsSearchResult } from 'app/shared/models/queryModel/visitAdmissionsSearchResult';
import { throwError } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class AdmissionService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private doctorReleaseUrl: string;
    private openToReleaseUrl: string;
    private releaseToOpenUrl: string;
    private admissionReasonByAdmissionId: string;
    private notifyPyxisOnPatientUpdateUrl: string;
    private notifyPyxisOnMergePatientUrl: string;
    private _setReleaseDate: string;
    private _setDischargeDate: string;
    private _setAdmissionDoctor: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration, private _pharmacyApiConfiguration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.admission.url;
        this.doctorReleaseUrl = this.actionUrl + _configuration.admission.doctorRelease;
        this.openToReleaseUrl = this.actionUrl + _configuration.admission.openToRelease;
        this.releaseToOpenUrl = this.actionUrl + _configuration.admission.releaseToOpen;
        this.admissionReasonByAdmissionId = this.actionUrl + _configuration.admission.admissionReasonByAdmissionId;
        this.notifyPyxisOnPatientUpdateUrl = this.actionUrl + _configuration.admission.notifyPyxisOnPatientUpdate;
        this.notifyPyxisOnMergePatientUrl = this.actionUrl + _configuration.admission.notifyPyxisOnMergePatient;
        this._setReleaseDate = this.actionUrl + _configuration.admission.setReleaseDate;
        this._setDischargeDate = this.actionUrl + _configuration.admission.setDischargeDate;
        this._setAdmissionDoctor = this.actionUrl + _configuration.admission.setAdmissionDoctor;
    }

    getAdmittedPatientForCompanion = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/GetAdmittedPatientForCompanion?patientId=' + patientId);
    }

    notifyPyxisOnPatientUpdate = (patientId: number) => {
        return this._http.put<any>(`${this.notifyPyxisOnPatientUpdateUrl}?patientId=${patientId}`, null);
    }

    notifyPyxisOnMergePatient = (patientId: number, oldPatientCodes: string[]) => {
        return this._http.put<any>(`${this.notifyPyxisOnMergePatientUrl}?patientId=${patientId}`, JSON.stringify(oldPatientCodes));
    }

    getAdmissionReasonByAdmissionId = (admissionId: number): Observable<any> => {
        return this._http.get<any>(`${this.admissionReasonByAdmissionId}?admissionId=${admissionId}`);
    }


    getAdmissionStatusById = (id: number): Observable<any> => {
        return this._http.get<any>(this._url + '/AdmissionStatus?id=' + id);
    }

    getById = (id: number): Observable<any> => {
        return this._http.get<any>(this._url + '/ById?id=' + id);
    }

    getDischargeStatus = (admissionId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/DischargeStatus?admissionId=' + admissionId);
    }


    getAdmissionInfomation = (id: number): Observable<any> => {
        return this._http.get<any>(this._url + '/AdmissionInfomation?id=' + id);
    }

    admissionInfo = (id: number): Observable<any> => {
        const url = `${this._url}/AdmissionInfo?id=${id}`;
        return this._http.get<any>(url);
    }

    drugCardStatus = (id: number, status: number): Observable<any> => {

        return this._http.get<any>(`${this._pharmacyApiConfiguration.server}${this._pharmacyApiConfiguration.returnRequest.pendingReturnRequestCount}?encounterId=${id}`)
            .pipe(
                map((res: Response) => res),
                concatMap((pendingRequestCount: any) => pendingRequestCount === 0 ?
                    this._http.put<any>(`${this._url}/DrugCardStatus?id=${id}&status=${status}`, null)
                    : throwError(new Error(`This Encounter have ${pendingRequestCount} Pending return request...!`)))
            );
    }

    releasePermissionDrugCardAdmissions = (): Observable<any> => {
        return this._http.get<any>(this._url + '/ReleasePermissionDrugCardAdmissions');
    }

    getDoctorReleaseAdmissions(): Observable<any> {
        return this._http.get<any>(this.doctorReleaseUrl);
    }

    getOpenedAdmissionId = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/OpenedAdmissionId?patientId=' + patientId);
    }

    getAdmittedPatient = (admittedPatientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/GetAdmittedPatient?admittedPatientId=' + admittedPatientId);
    }

    getAdmittedPatientForDischarge = (admittedPatientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/GetAdmittedPatientForDischarge?dischargePatientId=' + admittedPatientId);
    }

    getAdmittedPatientByDoctorId = (isActiveEncounter: Boolean): Observable<any> => {
        return this._http.get<any>(this._url + '/DoctorPatient?isActiveEncounter=' + isActiveEncounter);
    }

    getAdmittedPatientByNurseId = (isActiveEncounter: Boolean): Observable<any> => {
        return this._http.get<any>(this._url + '/NurseSectionsPatient?isActiveEncounter=' + isActiveEncounter);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    discharge = (admissionId, data): Observable<any> => {
        return this._http.post<any>(this._url + '/DischargePatient?admissionId=' + admissionId, JSON.stringify(data));
    }


    cancelAdmission = (admissionId: any, data: any): Observable<any> => {
        return this._http.post<any>(this._url + '/PostCancelAdmission?admissionId=' + admissionId, JSON.stringify(data));
    }

    openToReleaseAdmission = (admissionID: number, dischargeStatusID: number): Observable<any> => {

        const data = {
            admissionId: admissionID,
            dischargeStatusId: dischargeStatusID,
        };

        return this._http.post<any>(this.openToReleaseUrl, JSON.stringify(data));
    }

    releaseToOpenAdmission = (admissionId: number): Observable<any> => {
        return this._http.post<any>(this.releaseToOpenUrl, JSON.stringify(admissionId));
    }

    updateAdmissionDetails = (visitAdmissionsSearchResult: VisitAdmissionsSearchResult): Observable<any> => {
        return this._http.put<any>(this._url + '?cashPriceListId=' + visitAdmissionsSearchResult.CashPriceListId + '&&oldCashPriceListId=' + visitAdmissionsSearchResult.OldCashPriceListId, JSON.stringify(visitAdmissionsSearchResult));
    }
    // get count admmited patients 
    getTotalCountAdmitedPatients = (): Observable<any> => {
        return this._http.get<any>(this._url + '/TotalAdmmited');
    }

    getOccupancyRate = (): Observable<any> => {
        return this._http.get<any>(this._url + '/OccupancyRate');
    }

    getAdmittedPatientByAdmissionId = (admissionId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/AdmittedPatientByAdmissionId?admissionId=' + admissionId);
    }
    getAdmissionFullInfoByAdmissionId = (admissionId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/AdmissionFullInfoById?admissionId=' + admissionId);
    }

    getAdmissionInfoForHospitalRoom = (admissionId: number): Observable<any> => {
        const url = `${this._url}/AdmissionInfoForHospitalRoom?admissionId=${admissionId}`;
        return this._http.get<any>(url);
    }

    getAllPatientEncounters = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/AllPatientEncounters?patientId=' + patientId);
    }

    setReleaseDate = (id, releaseDate): Observable<any> => {
        return this._http.put<any>(`${this._setReleaseDate}/${id}`, JSON.stringify(releaseDate));
    }

    setDischargeDate = (patientId, id, dischargeDate): Observable<any> => {
        return this._http.put<any>(this._setDischargeDate + '?patientId=' + patientId + '&&id=' + id, JSON.stringify(dischargeDate));
    }

    getAdmissionDoctorId = (encounterId: number): Observable<number> => {
        return this._http.get<any>(this._url + '/AdmissionDoctorId?encounterId=' + encounterId);
    }

    setAdmissionDoctor = (doctorId: number, encounterId: number): Observable<any> => {
        return this._http.put<any>(`${this._setAdmissionDoctor}?doctorId=${doctorId}&id=${encounterId}`, encounterId);
    }

    getReleaseDate = (patientId: number, encounterId: number): Observable<number> => {
        return this._http.get<any>(`${this._url}/GetReleaseDate?patientId=${patientId}&encounterId=${encounterId}`);
    }

    getPatientHistory = (patientId): Observable<any> => {
        return this._http.get<any>(`${environment.admission}Admission/PatientHistory?patientId=${patientId}`);
    }
}