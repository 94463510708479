import { DateType } from 'app/shared/controls/hijri-gregorian-datepicker/hijri-gregorian-datepicker/date-type';
import { Staff } from 'app/shared/models/orders-and-billing/staff.model';

export interface IPatientMetaData {
    DocumentType: string;
    OtherDocumentType: string;
}

export class Patient implements IPatientMetaData {
    Id: number;
    FacilityId: number;
    PatientCode: string;
    FirstName: string;
    FatherName: string;
    MidName: string;
    LastName: string;
    OtherFirstName: string;
    OtherFatherName: string;
    OtherMidName: string;
    OtherLastName: string;
    BirthDate?: Date;
    GenderId?: number;
    MaritalStatusId?: number;
    ReligionId?: number;
    NationalityId?: number;
    NationalCode: string;
    BloodGroupId?: number;
    CountryId?: number;
    CityId?: number;
    Occupation: string;
    PoBox: string;
    Address: string;
    Email: string;
    SpouseName: string;
    OtherSpouseName: string;
    EntryDate: Date;
    Height?: number;
    Weight?: number;
    BodyMass?: number;
    IsDied: boolean;
    MotherName: string;
    MotherId?: number;

    Remarks: string;
    PatientImage: string;
    OpiningSectionId?: number;
    NewPatientCode: string;
    FileLocationId?: number;
    FileCreationDate?: Date;
    Status: number;
    IsActive: boolean;
    IsEmployee: boolean;
    EmployeeId?: number;
    UnknownSerialNo?: number;
    IsUnknown?: boolean;
    TelTypeId?: number;
    TelCode: string;
    IsHeadOfFamily?: boolean;
    IsArchived?: boolean;
    DocumentId?: number;
    DocumentCode: string;
    Age: string;
    IsBlocked?: boolean;
    BlockReason: string;
    IsDonor: boolean;

    FullName: string;
    OtherFullName: string;

    DocumentType: string;
    OtherDocumentType: string;
    StaffCreatedBy: Staff = new Staff();

    ContactRelationshipId?: number;
    ContactFullName?: string;
    ContactTelCode?: string;
    GenderTitle?: string;
    NationalityTitle?: string;
    DateType: DateType;

    SecondPhoneType?: number;
    SecondPhoneNumber: string;
    appId: number;
    AreaId: number;
    PrimaryDoctorId?: number;
    PatientPrimaryDoctor: Staff = new Staff();
    DonorTo?: number;
    DonorToPatient: any;
    IsResearch: boolean
    Language: number;
    MethodComm: number;
    HearUs: number;
    LastHospital: string;
    Other: string;
    public constructor(init?: Partial<Patient>) {
        Object.assign(this, init);
    }

}
