import {
    FacilityService,
    CoreApiConfiguration,
    LookUpService,
    PatientService,
    DiagnoseService,
    CodeService,
    MedicalCategoryService,
    DoctorClinicService,
    PatientVisitService,
    FileRequestService,
    PatientKinService,
    NameService,
    PatientDocumentService,
    AllergyService,
    AllergyTypeService,
    PatientAllergyService,
    PatientDiagnosisService,
    UserSectionService,
    MainStaffService,
    FacilityUnitService,
    PBLSetupService,
    CPOESetupService,
    ErAreaService,
    PatientEncounterProblemService,
    OrganizationSectionService,
    DiseaseCodeService,
    ReportService,
    StoreService,
    TimelineHistoryService,
    PatientVitalSignService,
    PatientRiskFactorService,
    CarePlanShortcutService,
    PatientDietService,
    SpecialityService,
    FavoritePageStaffService,
    SystemAllergyService,
    PatientInfectiousService,
    MedicalFileSetupService,
    ExecuteQueryService,
    SessionVisitService,
    NotificationRoleService,
    SMSSetupService,
    ClinicalDocumentaionPermissionService,
    DynamicFormsSetupService,
    TriageScaleSetupService,
    RadiologySetupService,
    MRNSetupService,
    PharmacySetupService,
    PharmacyRegisterationSetupService,
    MobileSetupService,
    BlockedMedicalFileSetupService,
    PatientProblemService,
    VitalSignsService,
    VitalSignNormalRangService,
    AdmissionSetupService,
    SpecialityWidgetService,
    AppointmentSetupService,
    SickLeaveService,
    UpdateOutpatientSetupService,
    DocumentAlertSetupService,
    PreRequisiteMedicalEntrySetupService,
    ExperienceSetupService

} from './services/core';
import { ErAreaBedsService } from './services/core/erAreaBeds';

export const NGA_CORE_SERVICES = [
    FacilityService,
    CoreApiConfiguration,
    LookUpService,
    PatientService,
    DiagnoseService,
    CodeService,
    MedicalCategoryService,
    DoctorClinicService,
    PatientVisitService,
    FileRequestService,
    PatientKinService,
    NameService,
    PatientDocumentService,
    AllergyService,
    AllergyTypeService,
    PatientAllergyService,
    PatientDiagnosisService,
    UserSectionService,
    MainStaffService,
    FacilityUnitService,
    PBLSetupService,
    CPOESetupService,
    PatientEncounterProblemService,
    OrganizationSectionService,
    DiseaseCodeService,
    ReportService,
    StoreService,
    TimelineHistoryService,
    PatientVitalSignService,
    PatientRiskFactorService,
    CarePlanShortcutService,
    PatientDietService,
    SpecialityService,
    FavoritePageStaffService,
    SystemAllergyService,
    ErAreaService,
    PatientInfectiousService,
    MedicalFileSetupService,
    ExecuteQueryService,
    SessionVisitService,
    NotificationRoleService,
    SMSSetupService,
    ClinicalDocumentaionPermissionService,
    DynamicFormsSetupService,
    RadiologySetupService,
    TriageScaleSetupService,
    ErAreaBedsService,
    MRNSetupService,
    PharmacySetupService,
    PharmacyRegisterationSetupService,
    MobileSetupService,
    BlockedMedicalFileSetupService,
    PatientProblemService,
    VitalSignsService,
    VitalSignNormalRangService,
    AdmissionSetupService,
    SpecialityWidgetService,
    AppointmentSetupService,
    SickLeaveService,
    UpdateOutpatientSetupService,
    DocumentAlertSetupService,
    PreRequisiteMedicalEntrySetupService,
    ExperienceSetupService
];
