import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class LabApiConfiguration {
    server: string;

    constructor() {
        this.server = environment.lab;
    }

    // getSpecimenBarCodeReportURL() {
    //     return environment.specimenBarcodeReportViewer;
    // }
    antibiotic = {
        url: 'Antibiotic',
        ingredient: 'Antibiotic/Ingredient',
    };

    commentTemplate = {
        url: 'CommentTemplate',
        getBySectionId: 'CommentTemplate/GetBySection',
    };

    LabfavoriteList = {
        url: 'FavoriteList',
        addList: 'FavoriteList/AddList',
    };

    externalLaboratory = {
        url: 'ExternalLaboratory',
        addExtrnalLabItemCost: 'ExternalLaboratory/AddExternalLabItemCost',
        getExtrnalLabItemCost: 'ExternalLaboratory/ExternalLabItemCost',
        getExternalLabAllItems: 'ExternalLaboratory/ExternalLabAllItems',
        checkExternalLabItem: 'ExternalLaboratory/CheckExternalLabItem',
        getTestDefinitionItems: 'ExternalLaboratory/TestDefinitionItems',
        checkExternalLabItems: 'ExternalLaboratory/CheckExternalLabItems',
        removeExtrnalLabItemCost: 'ExternalLaboratory/RemoveExternalLabItemCost',
        editExtrnalLabItemCost: 'ExternalLaboratory/EditExternalLabItemCost',
    };

    groupTest = {
        url: 'GroupTest',
        isSingleTestDuplicatedWithinSameOrder: 'GroupTest/IsSingleTestDuplicatedWithinSameOrder',
    };

    kitSetup = {
        url: 'KitSetup',
    };

    listDetail = {
        url: 'ListDetail',
        getByResultListId: 'ListDetail/GetByResultListId',
    };

    lookup = {
        url: 'Lookup',
    };

    lookupCategory = {
        url: 'LookupCategory',
    };

    microbiologyPanel = {
        url: 'MicrobiologyPanel',
    };

    microbiologyPanelAntibiotic = {
        url: 'MicrobiologyPanelAntibiotic',
    };

    normalResult = {
        url: 'NormalResult',
        filterNormalResultEntry: 'NormalResult/FilterNormalResultEntry',
        getNormalResultPatientHistoryForItem: 'NormalResult/GetNormalResultPatientHistoryForItem',
        calculateResult: 'NormalResult/CalculateResult',
        changeIsCompleteStatus: 'NormalResult/ChangeIsCompleteStatus',
        changeIsReleaseStatus: 'NormalResult/ChangeIsReleaseStatus',
        changeResultType: 'NormalResult/ChangeResultType',
        getSingleNormalResult: 'NormalResult/SingleNormalResult',
        encounterNormalResult: 'NormalResult/EncounterNormalResult',
        changeIsConfirmStatus: 'NormalResult/ConfirmReleaseResult',
        auditLog: 'NormalResult/AuditLog',
        publishNormalResultHistory: 'NormalResult/NormalResultHistory',
        reCalculateNormalRanges: 'NormalResult/ReCalculateNormalRanges',
        changeIsInfectiousStatus: 'NormalResult/ChangeIsInfectiousStatus',
        getLabResults: 'NormalResult/GetLabResults',
        getLabResultByEncounters: 'NormalResult/LabResultByEncounters',
        getItemByNormalResult: 'NormalResult/ItemByNormalResult',
    };

    previousResult = {
        url: 'PreviousResult',
    };

    resultFormulaTest = {
        url: 'ResultFormulaTest',
    };

    resultList = {
        url: 'ResultList',
    };

    section = {
        url: 'Section',
    };

    sectionTemplate = {
        url: 'SectionTemplate',
        getBySectionId: 'SectionTemplate/SectionTemplates',
        getById: 'SectionTemplate/Template',
    };

    sensitiveMicrobiologyAnti = {
        url: 'SensitiveMicrobiologyAnti',
        checkSensitiveMicrobiologyAntis: 'SensitiveMicrobiologyAnti/CheckSensitiveMicrobiologyAntis',
        insertAndGetSensitiveMicrobiologyAntis: 'SensitiveMicrobiologyAnti/InsertAndGetSensitiveMicrobiologyAntis',
    };

    sensitiveMicrobiology = {
        url: 'SensitiveMicrobiology',
        changeIsCompleteStatus: 'SensitiveMicrobiology/ChangeIsCompleteStatus',
        changeIsReleaseStatus: 'SensitiveMicrobiology/ChangeIsReleaseStatus',
    };

    sensitiveResult = {
        url: 'SensitiveResult',
        insertAndGetSensitiveResultsFromWorkbensh: 'SensitiveResult/InsertAndGetSensitiveResultsFromWorkbensh',
        changeIsCompleteStatus: 'SensitiveResult/ChangeIsCompleteStatus',
        changeIsReleaseStatus: 'SensitiveResult/ChangeIsReleaseStatus',
        getPhysicianCultureResult: 'SensitiveResult/PhysicianCultureResult',
        getPhysicianCultureResultDetails: 'SensitiveResult/PhysicianCultureResultDetails',
        publishCultureTestHistory: 'SensitiveResult/CultureTestHistory',
        getItemBySensitiveResult: 'SensitiveResult/ItemBySensitiveResult',
    };

    sentExternalLaboratory = {
        getAll: 'SentExternalLaboratory',
        newOrderUrl: 'SentExternalLaboratory/NewOrder',
        resendOrderUrl: 'SentExternalLaboratory/ResendOrder',
        cancelOrderUrl: 'SentExternalLaboratory/CancelOrder',
        uploadDocumentUrl: 'SentExternalLaboratory/UploadDocument',
        receiveOrderUrl: 'SentExternalLaboratory/ReceiveOrder',
        getDocumentsUrl: 'SentExternalLaboratory/GetAllDocuments',
        downloadDocumentUrl: 'SentExternalLaboratory/DownloadDocument',
        changeIsCompleteStatus: 'SentExternalLaboratory/ChangeIsCompleteStatus',
        changeIsReleaseStatus: 'SentExternalLaboratory/ChangeIsReleaseStatus',
        changeDocumentStatus: 'SentExternalLaboratory/ChangeDocumentStatus',
    };

    specimenRejectionReason = {
        url: 'SpecimenRejectionReason',
    };

    specimenTransaction = {
        specimenFilter: 'SpecimenTransaction/FilterSpecimen',
        filterSpecimenResultText: 'SpecimenTransaction/FilterSpecimenResultText',
        filterSpecimenTransactionForTechnicianWorkbench: 'SpecimenTransaction/FilterSpecimenTransactionForTechnicianWorkbench',
        filterSensitivityResultEntry: 'SpecimenTransaction/FilterSensitivityResultEntry',
        filterExternalLabSpecimenItemsUrl: 'SpecimenTransaction/filterExternalLabSpecimenItems',
        getSpecimenTransactionPatientHistoryForItem: 'SpecimenTransaction/GetSpecimenTransactionPatientHistoryForItem',
        getPreviousReleasedTestsByPatientId: 'SpecimenTransaction/PreviousReleasedTestsByPatientId',
        filterLabOrderSpecimenTestSearch: 'SpecimenTransaction/FilterLabOrderSpecimenTestSearch',
        filterTatMonitorUrl: 'SpecimenTransaction/filterTatMonitor',
        getSpecimenTransactionsForCultureTests: 'SpecimenTransaction/GetSpecimenTransactionsForCultureTests',
        orderStatus: 'SpecimenTransaction/OrderStatus',
        uncollectedSpecimenFilter: 'SpecimenTransaction/FilterUnCollectedSpecimen',
        specimenDetail: 'SpecimenTransaction/SpecimenDetails',
        specimenTransactionToAnalyzer: 'SpecimenTransaction/SpecimenTransactionToAnalyzer',
        rerunTest: 'SpecimenTransaction/RerunTest',
        exportExternalLabSpecimenItemsToCsv: 'SpecimenTransaction/ExternalLabSpecimenItemsCsv',
        searchEngineUrl: 'SpecimenTransaction/SearchEngine',
        labProceduresUrl: 'SpecimenTransaction/LabProcedures',
        isTestRequestedBefore: 'SpecimenTransaction/IsTestRequestedBefore',
        labReportType: 'SpecimenTransaction/LabReportType',
        isCultureTestOrderedBefore: 'SpecimenTransaction/IsCultureTestOrderedBefore',
        labReportTypeByEncounter: 'SpecimenTransaction/LabReportTypeByEncounter',
        hasTextReport: 'SpecimenTransaction/HasTextReport',
        allSpecimenStatusCanceledOrReleased: 'SpecimenTransaction/AllSpecimenStatusCanceledOrReleased',
        sendReleasedResult: 'SpecimenTransaction/SendReleasedResults',
        sendReleasedResultByPatients: 'SpecimenTransaction/SendReleasedResultsByPatients'
    };

    specimenTransactionStatus = {
        changeToCollected: 'SpecimenTransactionStatus/ChangeToCollected',
        changeSpecimenTransactionStatus: 'SpecimenTransactionStatus/ChangeSpecimenTransactionStatus',
        patientRelease: 'SpecimenTransactionStatus/PatientRelease',
    };

    testDefinition = {
        url: 'TestDefinition',
        getTestsDefinitionGroup: 'TestDefinition/TestDefinitionGroup',
        getTestsDefinitionSingle: 'TestDefinition/TestDefinitionSingle',
        getIncludeTestNormalRange: 'TestDefinition',
        getCalculatedTests: 'TestDefinition/CalculatedTests',
        getNumericTests: 'TestDefinition/NumericTests',
        getIncludeResultFormulaTests: 'TestDefinition/ResultFormulaTests',
        getTestsFormulasUrl: 'TestDefinition/TestsFormula',
        getTestDefinitionItems: 'TestDefinition/TestDefinitionItems',
        getTestDefinitionSingleSameLabSection: 'TestDefinition/TestDefinitionSingleSameLabSection',
        getTestDefinitionSingleBySearch: 'TestDefinition/TestDefinitionSingleBySearch',
        getTestDefinitionById: 'TestDefinition/TestDefinitionById'
    };

    testInstruction = {
        url: 'TestInstruction',
    };

    testNormalRange = {
        url: 'TestNormalRange',
    };

    textResult = {
        url: 'TextResult',
        changeIsCompleteStatus: 'TextResult/ChangeIsCompleteStatus',
        changeIsReleaseStatus: 'TextResult/ChangeIsReleaseStatus',
    };

    textResultTemplate = {
        url: 'TextResultTemplate',
    };

    testMapping = {
        url: 'TestMapping',
        getMachines: 'TestMapping/Machines',
        getMachineTests: 'TestMapping/MachineTests',
        addTestCode: 'TestMapping/TestCode',
        updateTestCode: 'TestMapping/TestCode',
    };

    item = {
        url: 'Item',
    };

    reportTemplate = {
        url: 'ReportTemplate',
        all: 'ReportTemplate/All',
        allActive: 'ReportTemplate/AllActive',
    };
}
