import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { PatientVisitService } from 'app/shared/services/core/patientVisit/patientVisit.service';
import { VisitAdmissionsSearchResult } from 'app/shared/models/queryModel/visitAdmissionsSearchResult';
import { AdmissionVisitType } from 'app/shared/models/queryModel/enums/admission-visit-type';
import { AdmissionStationSearchService } from 'app/shared/services/queryModel/admission-station-search/admission-station-search.service'
import { AdmissionStatus } from "app/shared/models/admission/enums/admission-status";
import { PBLSetup } from 'app/shared/models';
import { PBLSetupService } from 'app/shared/services';

@Component({
    selector: 'visit-admissions-order-cmp',
    styleUrls: ['./visit-admissions-order-result.scss'],
    templateUrl: './visit-admissions-order-result.html',
})


export class VisitAdmissionsOrderResultComponent implements OnInit {

    @Output() onSetResult = new EventEmitter<any>();
    @Input() result;
    patients: any = [];
    displayOutVisitToAdmissionPatient: boolean = false;
    searchStatus: any = null;

    ngOnInit() {
        this.getDetails();
        this.getPblSetup();
    }

    constructor(private _patientVisitService: PatientVisitService
        , private _admissionStationSearchService: AdmissionStationSearchService
        , private _pblSetupService: PBLSetupService) {
    }

    getDetails() {
        if (this.result?.patientBasicSearchResult?.length) {
            this.patients = [];
            this.result.patientBasicSearchResult.map(patient => {
                const patientresult = { PatientId: patient.Id, FullName: patient.FullName, MRN: patient.PatientCode, Admissions: [], Visits: [] };
                this.patients.push(patientresult);
            });
        }
        this.searchStatus = this.result?.statusFilter || null;
    }

    private getPaymentTypeLabel(status: number) {
        status = Number(status) || 0;
        let label: string = '';
        switch (status) {
            case AdmissionStatus.InHospital:
                label = 'In Hospital';
                break;
            case AdmissionStatus.Canceled:
                label = 'Canceled';
                break;
            case AdmissionStatus.DrugCardClosed:
                label = 'Drug Card Closed';
                break;
            case AdmissionStatus.FinalDischarge:
                label = 'Final Discharge';
                break;
            case AdmissionStatus.FinanceSettled:
                label = 'Finance Settled';
                break;
            case AdmissionStatus.Halted:
                label = 'Halted';
                break;
            case AdmissionStatus.ReleasePermission:
                label = 'Release Permission';
                break;
        }

        return label;
    }

    getAdmissionVisit(item) {
        this.getPatientAdmissions(item);
        if(!this.displayOutVisitToAdmissionPatient) {
            this.getPatientVisits(item);
        }
    }

    onSelect(event) {
        console.log('item selected', event);
        const item = event;
        this.onSetResult.emit({ item });
    }

    getPatientAdmissions(selectedPatient) {
        this._admissionStationSearchService.getPatientAdmissionOrderSearch(selectedPatient.PatientId, this.searchStatus).subscribe((data: VisitAdmissionsSearchResult[]) => {
            if (data !== null) {
                for (const row of data) {
                    row.AdmissionDate = new Date(row.AdmissionDate);
                    row.Type = AdmissionVisitType.Admission;
                }
                this.patients.map(patient => {
                    patient.Admissions = [];
                    if (patient.PatientId === selectedPatient.PatientId) {
                        patient.Admissions = data;

                        if(this.displayOutVisitToAdmissionPatient && !this.searchStatus && data.length === 0) {
                            this.getPatientVisits(selectedPatient);
                        }

                        if (this.displayOutVisitToAdmissionPatient)
                            patient.Admissions = data.filter(f => f.Status === AdmissionStatus.InHospital || f.Status === AdmissionStatus.Halted);
                        this.sortAdmissionsAndVisits(patient);
                    }
                });
            }
        });
    }

    getPatientVisits(selectedPatient) {
        this._patientVisitService.getOpenVisitByPatientId(selectedPatient.PatientId).subscribe((data: VisitAdmissionsSearchResult[]) => {
            if (data.length > 0) {
                const patientVisits = this.patients.find(e => e.PatientId === selectedPatient.PatientId);
                patientVisits.Visits = [];

                data.forEach((a) => {
                    a.Type = AdmissionVisitType.PatientVisit;
                    a.VisitDate = new Date(a.VisitDate);

                    if (patientVisits) {
                        patientVisits.Visits.push(a);
                        this.sortAdmissionsAndVisits(patientVisits);
                    }
                });
            }
        });
    }

    sortAdmissionsAndVisits(patient) {
        if (patient.Admissions && patient.Admissions.length > 0) {
          patient.Admissions.sort((a, b) => b.AdmissionDate.getTime() - a.AdmissionDate.getTime());
        }
        if (patient.Visits && patient.Visits.length > 0) {
          patient.Visits.sort((a, b) => b.VisitDate.getTime() - a.VisitDate.getTime());
        }
    }

    getPblSetup() {
        this._pblSetupService.getCurrentFacilityPBL().subscribe((data: PBLSetup) => {
            this.displayOutVisitToAdmissionPatient = data?.DisplayOutVisitToAdmissionPatient;
        });
    }

}
