import { PblAction } from "./enums";
import { PricingPer } from "./enums/pricing-per";
import { ReIssueInvoiceDate } from "./enums/reIssue-invoice-date";


export class PBLSetup {

    Id: number = 0;
    AutoDischargeOpd: number = 0;
    AutoDischargeEr: number = 0;
    IsAdmitUnknown: number = 0;
    RegistrationReqFields: string = '';
    ValidateDupFields: string = '';
    OtherValidateDupFields: string = '';
    ValidateAction: number = 0;
    OtherValidateAction: number = 0;
    IsActiveMultiStatement: number = 0;
    ExcludedPriceListId: number = 0;
    DischargePrescrGroupId: number = 0;
    AllowRefundForOpenAndHaltStatement: number = 0;
    DispatchedAmount: number = 0;
    SendDrfeesToDrfeesFund: number = 0;
    PrintLabResultForBlockedStatement: boolean;
    SIUnitLab: boolean;
    NationalIdLength: number = null;
    CashDifferenceServiceId?: number = null;
    IssueErVisitWithoutDischarge: boolean;
    PrintLabResultForUnpaidStatement: number = 0;
    BlockPatientAction: number = 0;
    CheckDiscountAverageCost: boolean;
    DiscountAverageCostAction?: number = null;
    ProfitAndLoseServiceId: number = null;
    PackageElementId: number = null;
    AssignTaskBySupervisor: boolean;
    PrintZeroInvoice: boolean;
    IsWorkSheetTemplate: boolean;
    CreateAutoPaymentForERVisit: boolean;
    CreateAutoPaymentForOPDVisit: boolean;
    ExcludeCashAmountFromCreditInvoiceReport: boolean;
    CreditDoctorPaymentWithoutSettlement: boolean;
    LabResultStamp: boolean;
    GenerateERInvoicePerOrder: boolean;
    GenerateOPDInvoicePerOrder: boolean;
    DefaultSessionPeriod?: number = null;
    DefaultERSessionPeriod?: number = null;
    ApplyVAT: boolean;
    EnableVAT: boolean;
    SystemCountry: number = null;
    ApprovalFollowUpDelay: number = null;
    DoctorSignatureOrders: boolean;
    DoctorSignaturePrescription: boolean;
    DoctorSignatureMedicalForms: boolean;
    SetUrgentPriorityToERPatient: boolean;
    PayerTemplateMasterCodes: boolean;

    ActivePendingAreaForCreditPatients: boolean;
    ActivePendingAreaForCashPatients: boolean;
    ReasonForExam: boolean;
    LabResultStatus: string = '';
    AllowRadiologyTemplate: boolean;
    CheckDemographicInfo: boolean;
    PACSMessageOnReceive: boolean;
    MandatoryNationalIdOrDocumentNumber: boolean;
    MandatoryNationalIdAction: Number = -1;
    ApplyCompanyShareAfterApproval: boolean;
    EnableHijriDate: boolean;
    OperationSectionId?: number;
    PrintCashCreditInvoice: boolean;
    ApprovalFirstMaternity: boolean;
    ApprovalRequiredOnRefund: boolean;
    ApprovalRequiredOnDiscount: boolean;
    CheckDocumentExpiryDate: boolean;
    DocumentExpiryDateAction?: number = null;
    CheckBalanceAmount: boolean = false;
    PercentageBalanceAmount?: number = 0;
    BalanceAmountActionType: PblAction = PblAction.Alert;
    EnableMOH: boolean;
    DiscontinueDoctorSignaturePrescription: boolean;
    StaticValueOnNationalNumber: string = '';
    IssueInvoicePopup: boolean;
    PrintSpecimenFromLIS: boolean;
    ReIssueInvoiceDate: number;
    LegalAge: number;
    LISLabOrderSendMode: number;
    PrintAppointmentSlip: boolean;
    PricingPer: PricingPer = PricingPer.ItemDefaultUnit;
    DisplayOutVisitToAdmissionPatient: boolean;
    ValidateServicesBySections: boolean;

    constructor() {
        this.Id = 0;
        this.AutoDischargeOpd = 0;
        this.AutoDischargeEr = 0;
        this.IsAdmitUnknown = 0;
        this.RegistrationReqFields = '';
        this.ValidateDupFields = '';
        this.OtherValidateDupFields = '';
        this.ValidateAction = 0;
        this.OtherValidateAction = 0;
        this.IsActiveMultiStatement = 0;
        this.ExcludedPriceListId = 0;
        this.DischargePrescrGroupId = 0;
        this.AllowRefundForOpenAndHaltStatement = 0;
        this.DispatchedAmount = 0;
        this.SendDrfeesToDrfeesFund = 0;
        this.NationalIdLength = null;
        this.PrintLabResultForUnpaidStatement = 0;
        this.BlockPatientAction = 0;
        this.DiscountAverageCostAction = null;
        this.ProfitAndLoseServiceId = 0;
        this.PackageElementId = 0;
        this.SystemCountry = null;
        this.LabResultStatus = '';
        this.MandatoryNationalIdAction = -1;
        this.OperationSectionId = null;
        this.ApprovalRequiredOnRefund = false;
        this.ApprovalRequiredOnDiscount = false;
        this.CheckBalanceAmount = false;
        this.PercentageBalanceAmount = 0;
        this.BalanceAmountActionType = PblAction.Alert;
        this.DiscontinueDoctorSignaturePrescription = false;
        this.StaticValueOnNationalNumber = '';
        this.ReIssueInvoiceDate = ReIssueInvoiceDate.DueDate;
        this.LegalAge = null;
        this.PrintAppointmentSlip = false;
        this.PricingPer= PricingPer.ItemDefaultUnit;
        this.ValidateServicesBySections = false;
    }
}
