export const environment = {
    production: true,
    useAuthService: true,
    admission: "http://physioone.certacure.com:8083/api/",
    core: "http://physioone.certacure.com:8084/api/",
    lab: "http://physioone.certacure.com:8085/api/",
    pharmacy: "http://physioone.certacure.com:8087/api/",
    nurse: "http://physioone.certacure.com:8098/api/",
    queryModel: "http://physioone.certacure.com:8088/api/",
    order: "http://physioone.certacure.com:8089/api/",
    authorityService: "http://physioone.certacure.com:9090",
    redirectURI: "http://physioone.certacure.com/callback",
    silentRedirectURI: "http://physioone.certacure.com/silent",
    postLogoutRedirectURI: "http://physioone.certacure.com",
    reportViewer: "http://physioone.certacure.com:9092/Default.aspx",
    reportViewerWithParams: "http://physioone.certacure.com:9092/ReportWithParam.aspx",
    crystalReportViewer: "http://physioone.certacure.com:9002/CrystalReport.aspx",
    specimenBarcodeReportViewer: "http://physioone.certacure.com:9092/LabSpecimen.aspx",
    notificationURI: "ws://physioone.certacure.com:8181/connect?access_token=",
    sendNotificationURI: "http://physioone.certacure.com:8181/api/Notification",
    coreImagePath: "http://physioone.certacure.com:8084/imageStorage/",
    dynamicFormViewer: "http://physioone.certacure.com:9095",
    dynamicFormPath: "http://physioone.certacure.com:9099/api/",
    webSocketRetry: 1,
    autoRefreshSeconds: 30,
    nursingManagement: "http://physioone.certacure.com:8090",
    eClaimIntegration: false,
    dimensionUrl: "http://physioone.certacure.com:9081/api/",
    dimension: "https://tpo-stg.ehc.jo/api/",
    dimensionAttachement: "https://tpo-stg.ehc.jo:8000/api/",
    dimensionLicenseID: "JOR-F-000011",
    dimensionUsername: "Istishari",
    dimensionPassword: "iii.123$",
    dimensionDemographicInfo: "https://PRD-EHSAPI.ehc.jo/Api/v1/index.php",
    appointment: "http://physioone.certacure.com:8093/api/",
    appointmentURI: "http://physioone.certacure.com:8094/",
    mediSpanIntegration: false,
    mobileIntegrationEnabled: false,
    mediSpanUrl: "http://physioone.certacure.com:8086/api/",
    operationManagement: "http://physioone.certacure.com:6601",
    cpoeManagement: "http://physioone.certacure.com:5515",
    queryBuilder: "http://physioone.certacure.com:8081/api",
    sqlBuilderEngine: "http://physioone.certacure.com:6602",
    worksheetTemplate: "_WorkSheet",
    notification: "http://physioone.certacure.com:8282/",
    enableNotification: false,
    enableOncology: false,
    oncology: "http://physioone.certacure.com:6604/api/",
    oncologyManagement: "http://physioone.certacure.com:6605",
    enableAppointment: true,
    outPatientMonitorIntervalCallback: 1,
    operation: "http://physioone.certacure.com:8081/api/",
    enableOperation: false,
    httpAggregatorApi: "http://physioone.certacure.com:6655/api/",
    specimenLISReportURI: "http://something/orderId=",
    hangFire: "http://physioone.certacure.com:7204/api/",
    allowMultiReturn: false,
    cateringManagement: "http://physioone.certacure.com:5555",
    discountSetup: 2, // 0 = Disable Discount, 1 = Enable Discount Approve, 2 = Discount Privilege
    labReportViewer: "http://physioone.certacure.com:9092/LabSpecimen.aspx",
};