import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { AppModule } from 'app/app.module';
import { Subject } from 'rxjs';
import { ReceiverModule } from 'app/shared/models/enum/receiver-module';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SignalrService {
    private toastrService: ToastrService;
    onFinishConnect = new Subject<boolean>();
    responses: Subject<any>[] = [];
    _hubConnections: { hub: HubConnection, config: any }[] = [];
    server: string;
    securityServer: string;
  
    public constructor() {
        this.toastrService = AppModule.injector.get(ToastrService);
        this.server = environment.notification;
        this.securityServer = environment.authorityService;
    }

    connect = (hubName: string, subscribeTo, config: { subcribeToUser: boolean, notifyWhenReceived: boolean, notifyMessage: string } = null): void => {
        if (!this._hubConnections[hubName]) {
            const user = this.connectedStaff();
            if (!user) {
                return
            }

            const hubConnection = new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Debug)
                .withUrl(`${this.server}${hubName}`, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                }).withAutomaticReconnect()
                .build();

            hubConnection.start()
                .then(() => {
                    if (config?.subcribeToUser) {
                        this.onConnect(hubConnection, subscribeTo, user);
                    }
                    console.log(`signalr connection started With ${hubName} hub`)
                })
                .catch((err) => console.log('error while establishing signalr connection: ' + err));

            hubConnection.onreconnected(c => {
                console.log('signalr reconnect successfully')
                if (config?.subcribeToUser) {
                    this.onConnect(hubConnection, subscribeTo, user);
                }
            });
            if (!this._hubConnections[hubName]) {
                this._hubConnections[hubName] = { hub: hubConnection, config: config };
            }
        }
    }

    onConnect = (hubConnection: HubConnection, subscribeTo, user): void => {
        hubConnection.send(subscribeTo, ReceiverModule.His, user).then(() => {
            this.onFinishConnect.next(true);
        });

    }

    on = (hubName: string, reveivedMethod: string): Subject<any> => {
        if (this._hubConnections[hubName]) {
            if (!this.responses[reveivedMethod]) {
                this.responses[reveivedMethod] = new Subject<any>();
            }
            this._hubConnections[hubName].hub.on(reveivedMethod, (value) => {
                this.responses[reveivedMethod].next(value);
                if (this._hubConnections[hubName]?.config?.notifyWhenReceived) {
                    this.toastrService.info(this._hubConnections[hubName]?.config?.notifyMessage, 'Info');
                }
            });
        }
        return this.responses[reveivedMethod] || new Subject<any>();
    }

    send = (hubName: string, methodName: string, data: any): Promise<any> => {
        if (this._hubConnections[hubName]) {
            return this._hubConnections[hubName].hub.send(methodName, data);
        }
    }

    connectedStaff(): any {
        const userData = JSON.parse(sessionStorage.getItem(`oidc.user:${this.securityServer}:js`))?.profile;
        if (!userData) {
            return null;
        }

        return {
            Id: '',
            HisUserId: userData.staff_id,
            RoleIds: Array.isArray(userData.role) ? userData.role : [userData.role],
            ConnectionId: '',
            CreatedDate: new Date(),
            FacilityId: userData.selected_facility
        };

    }
}

